<template>
  <div class="statistics">
    <div class="s-main">
      <div class="navHead"></div>
      <div class="survey">
        <div class="s-item">
          <div class="icon" style="background:#286DA8;">
            <img src="../../assets/images/myS1.png" alt />
          </div>
          <div class="info">
            <div class="label">{{corpData.name}}</div>
            <div class="text">{{corpData.value}}</div>
          </div>
        </div>
<!--        <div class="s-item">-->
<!--          <div class="icon" style="background:rgb(251, 132, 145);;">-->
<!--            <img src="../../assets/images/myS2.png" alt />-->
<!--          </div>-->
<!--          <div class="info">-->
<!--            <div class="label">{{surveyList[1].name}}</div>-->
<!--            <div class="text">{{surveyList[1].count || 0}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="s-item">-->
<!--          <div class="icon" style="background:rgb(232, 175, 125);">-->
<!--            <img src="../../assets/images/myS3.png" alt />-->
<!--          </div>-->
<!--          <div class="info">-->
<!--            <div class="label">{{surveyList[2].name}}</div>-->
<!--            <div class="text">{{surveyList[2].count || 0}}</div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="s-item">
          <div class="icon" style="background:rgb(159, 234, 255);">
            <img src="../../assets/images/myS4.png" alt />
          </div>
          <div class="info">
            <div class="label">{{ projectData.name }}</div>
            <div class="text">{{projectData.value}}</div>
          </div>
        </div>
        <div class="s-item">
          <div class="icon" style="background:#607d8b;">
            <img src="../../assets/images/myS5.png" alt />
          </div>
          <div class="info">
            <div class="label">{{classData.name}}</div>
            <div class="text">{{classData.value}}</div>
          </div>
        </div>
        <div class="s-item">
          <div class="icon" style="background:rgb(70 206 87);">
            <img src="../../assets/images/myS6.png" alt />
          </div>
          <div class="info">
            <div class="label">{{memberData.name}}</div>
            <div class="text">{{memberData.value}}</div>
          </div>
        </div>
      </div>

      <Tabs class="tabs" size="small" @on-click="onClickTab">
        <TabPane label="企业列表" name="corpList" context-menu>
            <Table :columns="corpColumn" :data="corpListData" size="small" ></Table>
            <Page class="page-box" style="padding: 10px 0;" @on-change="getDataList" :total="dataCorpListTotal" :current="dataCorpListPage" :page-size="dataCorpListSize" />
        </TabPane>
        <TabPane label="项目列表" name="projectList" context-menu>
          <Table :columns="projectColumn" :data="projectListData" size="small"></Table>
          <Page class="page-box" style="padding: 10px 0;" @on-change="getDataList" :total="dataProjectListTotal" :current="dataProjectListPage" :page-size="dataProjectListSize" />
        </TabPane>
        <TabPane label="班组列表" name="classList" context-menu>
          <Table :columns="classColumn" :data="classListData" size="small"></Table>
          <Page class="page-box" style="padding: 10px 0;" @on-change="getDataList" :total="dataClassListTotal" :current="dataClassListPage" :page-size="dataClassListSize" />
        </TabPane>
        <TabPane label="实名人员列表" name="memberList" context-menu>
          <Table :columns="memberColumn" :data="memberListData" size="small">
            <template slot-scope="{ row }" slot="sex">
              <strong>{{ formatSex(row.sex) }}</strong>
            </template>
          </Table>
          <Page class="page-box" style="padding: 10px 0;" @on-change="getDataList" :total="dataMemberListTotal" :current="dataMemberListPage" :page-size="dataMemberListSize" />
        </TabPane>
      </Tabs>

<!--      <Tabs class="tabs" type="card" @on-click="pickTaskTab">-->
<!--            <TabPane label="七天新增实名" name="11" context-menu></TabPane>-->
<!--            <TabPane label="七天启动次数" name="22" context-menu></TabPane>-->
<!--            <TabPane label="七天日活" name="33" context-menu></TabPane>-->
<!--            <TabPane label="月活" name="44" context-menu></TabPane>-->
<!--      </Tabs>-->
<!--      <div id = "chartLine" class="chart"></div>-->
<!--      <div class="detail">-->
<!--        <div class="second-title">-->
<!--          <div class="shu"></div>自定义事件-->
<!--        </div>-->
<!--        <div class="d-item">-->
<!--          <Dropdown trigger="click" style="">-->
<!--            <a href="javascript:void(0)">-->
<!--              {{currentDetail?currentDetail:'选择自定义事件'}}-->
<!--              <Icon type="ios-arrow-down"></Icon>-->
<!--            </a>-->
<!--            <DropdownMenu slot="list">-->
<!--              <DropdownItem v-for="(item,index) in detailList" :key="index" @click.native="pickDetail(item)">{{item.name}}</DropdownItem>-->
<!--            </DropdownMenu>-->
<!--          </Dropdown>-->
<!--        </div>-->
<!--        <div class="second-title">-->
<!--          <div class="shu"></div>事件统计明细-->
<!--        </div>-->
<!--        <div class="d-item">-->
<!--          <Table :columns="columns1" :data="data1"></Table>-->
<!--          <Page class="page-box" style="padding: 10px 0;" @on-change="handelChangePageSize" :total="listTotal" :current="listPage" :page-size="listSize" />-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <Modal
      v-model="pawFlag"
      :loading="toNext"
      :mask-closable="false"
      title="请输入授权码"
      @on-ok="toPaw(1)"
      @on-cancel="toPaw(2)"
    >
      <Input v-model="password_l" type="password" placeholder="输入授权码" style />
    </Modal>
  </div>
</template>
<script>
import * as echarts from "echarts";

export default {
  components: {},
  data() {
    return {
      columns1:[],
      data1: [],
      surveyList: [
        {count:0},
        {count:0},
        {count:0},
        {count:0}
      ],
      corpData:{
        name:'',
        value: 0,
      },
      projectData:{
        name:'',
        value: 0,
      },
      classData:{
        name:'',
        value: 0,
      },
      memberData:{
        name:'',
        value: 0,
      },

      corpListData:[],
      projectListData:[],
      classListData:[],
      memberListData:[],
      dataCorpListTotal:0,
      dataCorpListPage:1,
      dataCorpListSize:0,
      dataProjectListTotal:0,
      dataProjectListPage:1,
      dataProjectListSize:0,
      dataClassListTotal:0,
      dataClassListPage:1,
      dataClassListSize:0,
      dataMemberListTotal:0,
      dataMemberListPage:1,
      dataMemberListSize:0,
    // {title: 'id', key: 'id'},
      corpColumn:[{title: '企业名称', key: 'vname'}, {title: '企业负责人', key: 'vlinkMan'}, {title: '企业负责人号码', key: 'vlinkTel'}],
      projectColumn:[{title: '项目名称', key: 'vprojectname'}, {title: '项目负责人', key: 'vresponsiblePersonName'}, {title: '项目详细地址', key: 'fullLocation'}],
      classColumn:[{title: '班组名称', key: 'vteamName'}, {title: '班组负责人', key: 'vresponsiblePersonName'}, {title: '班组负责人号码', key: 'vresponsiblePersonPhone'}, {title: '所属企业', key: 'corpName'}],
      memberColumn:[{title: '姓名', key: 'realname'},  {title: '手机号码', key: 'phone'}, {title: '身份证号码', key: 'idCard'}, {title: '家庭住址', key: 'address'}],

      corpListQuery:{
        current: this.dataCorpListPage,
        size: 10,
        desc: 'id'
      },
      projectListQuery:{
        current: this.dataProjectListPage,
        size: 10,
        desc: 'id'
      },
      classListQuery:{
        current: this.dataClassListPage,
        size: 10,
        desc: 'id'
      },
      memberListQuery:{
        current: this.dataMemberListPage,
        size: 10,
        isRealname:1
      },

      detailList: [],
      currentDetail: '',
      listPage: 1,
      listSize: 20,
      listTotal: 0,
      currentId: null,
      pawFlag: false,
      toNext: true,
      password_l: "",
      currentTasksTab: 'corpList'
    };
  },
  filters: {},
  created() {},
  mounted() {
    this.pawFlag = true
    // this.getSurvey()
    // this.getDetailList()
    this.getData()
    this.getDataList(1)
  },
  destory() {},
  methods: {
    toPaw(type){
      if(type == 1){
        if(this.password_l === "dgtech"){
          this.pawFlag = false
        }else{
          this.toNext = false;
          setTimeout(() => {
            this.toNext = true;
          }, 100);
          this.$Message.error("授权密码错误");
        }
      }else{
        this.$router.push({ path: "/home" });
      }
    },
    async getSurvey(){
      let res = await this.$fetch(`/gbApi/primary`)
      this.surveyList = res.data
      this.getChartData('11')
    },
    async getDetailList(){
      let res = await this.$fetch(`/gbApi/stats/list`)
      this.detailList = res.data
    },
    pickDetail(val){
      this.currentId = val.id
      this.currentDetail = val.name
      this.listPage = 1
      this.getDetail(val.id)
    },
    async getDetail(id){
      let res = await this.$fetch(`/gbApi/stats/${id}?page=${this.listPage}&pageSize=${this.listSize}`)
      if(res.data.title){
        let llist = res.data.title
        let vlist = res.data.value
        let arr = []
        let data = []
        llist.forEach((val)=>{
          arr.push({
            title: val.name,
            key: 'data' + (val.id)
          })
        })
        let son = vlist[0].length
        vlist.forEach((val)=>{
          let n = {}
          for( let i = 0; i < son; i++){
            n['data' + (i + 1)] =  val[i].time
          }
          data.push(n)
        })
        this.listTotal = res.data.total
        this.columns1 = arr
        this.data1 = data
      }else{
        this.columns1 = []
        this.data1 = []
      }
    },
    handelChangePageSize(e){
      this.listPage = e
      this.getDetail(this.currentId)
    },
    pickTaskTab(e) {
      this.currentTasksTab = e;
      this.getChartData(e)
    },
    formatSex(sex){
      console.log(sex)
    },
    async getData(){
      let res = await this.$fetch(`/vapi/statistics`)
      this.projectData = res.data.projectData
      this.corpData = res.data.corpData
      this.classData = res.data.classData
      this.memberData = res.data.memberData
    },
    onClickTab(e){
      console.log(e)
      this.currentTasksTab = e;
      this.getDataList(1)
    },
    async getDataList(e){
      if(this.currentTasksTab === "corpList"){
        this.corpListQuery.current = e
        let params = this.corpListQuery
        let res = await this.$fetch(`/vapi/admin/hrCorp`, {params})
        this.corpListData = res.data.records
        this.dataCorpListTotal = res.data.total
        this.dataCorpListSize = res.data.size
        this.dataCorpListPage = res.data.current
      }else if(this.currentTasksTab === "projectList"){
        this.projectListQuery.current = e
        let params = this.projectListQuery
        let res = await this.$fetch(`/vapi/admin/project`, {params})
        this.projectListData = res.data.records
        this.dataProjectListTotal = res.data.total
        this.dataProjectListSize = res.data.size
        this.dataProjectListPage = res.data.current
      }else if(this.currentTasksTab === "classList"){
        this.classListQuery.current = e
        let params = this.classListQuery
        let res = await this.$fetch(`/vapi/admin/team`, {params})
        this.classListData = res.data.records
        this.dataClassListTotal = res.data.total
        this.dataClassListSize = res.data.size
        this.dataClassListPage = res.data.current
      }else {
        this.memberListQuery.current = e
        let params = this.memberListQuery
        let res = await this.$fetch(`/vapi/admin/user`, {params})
        this.memberListData = res.data.records
        this.dataMemberListTotal = res.data.total
        this.dataMemberListSize = res.data.size
        this.dataMemberListPage = res.data.current
      }
    },
    async getChartData(id){
      let res = await this.$fetch(`/gbApi/primary/chart/${id}`)
      let chartData = res.data.chart_data
      this.drawLineChart(chartData)
    },
    drawLineChart(data) {
      let list = data
      let xlist = []
      let dlist = []
      let total = 0
      total = list.length
      for (var i = 0, len = list.length; i < len; i++) {
        xlist.push(list[i]['xaxis'])
        dlist.push([list[i]['xaxis'], list[i]['yaxis']])
      }
      this.newTotal = total
      this.todayNew = dlist[6]
      this.chartLine = echarts.init(document.getElementById("chartLine"));
      let option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "3%",
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: "category"
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            formatter: "{value}", // 使用字符串模板，模板变量为刻度默认标签 {value}
          },
          splitLine: {
            show: false
          }
        },
        series: [{
            type: 'line',
            smooth: true,
            data: dlist,
            color: ["#2db7f5","#19be6b"]
        }],
      };
      this.chartLine.setOption(option);
    }
  }
};

</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.second-title {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  .shu {
    width: 5px;
    height: 14px;
    background: #409eff;
    margin-right: 6px;
  }
}
.statistics {
  .s-main {
    background: #fff;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
    padding: 30px 5vw;
  }
  .survey {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    .s-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .icon {
        width: 70px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: blue;
        border-radius: 8px;
        img {
          width: 50%;
          height: auto;
        }
      }
      .info {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 10px;
        width: 25vw;
        max-width: 110px;
        .label {
          font-size: 14px;
        }
        .text {
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
  }
  .detail {
    margin-top: 20px;
    width: 100%;
    .d-item {
      width: 100%;
      margin-bottom: 20px;
      padding: 20px 2vw;
    }
  }
  .chart{
     margin-top: 20px;
     width: 75%;
     height: 250px;
  }
  .tabs{
    margin-top: 20px;
  }
}
@keyframes myCardHover {
  0% {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  }
  100% {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.s-item:hover {
  .icon{
    animation: myCardHover 0.0001s linear;
    animation-fill-mode: both;
  }
}
</style>
